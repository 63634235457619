<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <div class="bg-black/90 text-white relative flex h-screen">
      <client-only>
        <UiDrawer class="start-0 z-10" />
      </client-only>
      <main
        id="main"
        class="w-full bg-gradient-to-b from-black from-45% to-gray-900/70 pb-12 h-full overflow-y-auto scrollbar-hide"
      >
        <slot></slot>
      </main>
    </div>
  </Html>
</template>
<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  // addSeoAttributes: true,
});
</script>
